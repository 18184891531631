<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
                class="text-h5"
            >Modifica Straordinario
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid >
                <v-row no-gutters class="px-12 mt-8">
                    <v-text-field
                        class="ml-2 mr-2"
                        type="number"
                        label="Ore"
                        outlined
                        v-model="editedOvertimeHours"
                    />
                    <v-text-field
                        class="ml-2 mr-2"
                        type="number"
                        label="Min"
                        outlined
                        v-model="editedOvertimeMinutes"
                    />
                </v-row>
            </v-container>
            <v-card-actions class="justify-end">
                <v-btn
                    color="orange lighten-2"
                    class="px-4"
                    @click="removeOvertime"
                    :disabled="overtimeMinutes == 0"
                >  
                    <v-icon left>
                        mdi-close
                    </v-icon>
                    Rimuovi
                </v-btn>
                <v-btn
                    color="green accent-2"
                    class="px-4"
                    :disabled="saveDisabled"
                    @click="saveMethod"
                >
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Salva
                </v-btn>
            </v-card-actions>   
        </v-card>  
    </v-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            editedOvertimeMinutes: 0,
            editedOvertimeHours: 0
        }
    },
    props: {
        overtimeMinutes: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        cancelMethod: function() {
            this.setNullOvertime();
            this.$emit('close-overtime-edit-dialog');
        },
        saveMethod: function() {
            if(!this.saveDisabled) {
                this.$emit('save-overtime', this.computedOvertimeMinutes);
                this.$emit('close-overtime-edit-dialog');
            }
        },
        removeOvertime: function() {
            if(this.overtimeMinutes > 0) {
                this.setNullOvertime();
                this.saveMethod();
            }
        },
        setNullOvertime: function() {
            this.editedOvertimeMinutes = 0;
            this.editedOvertimeHours = 0;
        }
        // deleteMethod: function() {
        //     this.$emit('close-absence-record-edit-dialog');
        //     this.$emit('delete-absence-record', this.record.dateIndex);
        // },
        // saveMethod: function() {
        //     this.$emit('save-absence-record', {absenceKind: this.absenceKind, minuteDuration: this.computedAbsenceMinutes});
        // }
    },
    watch: {
        show: function(val){
            if (val) {
                this.editedOvertimeHours = Math.floor(this.overtimeMinutes / 60);
                this.editedOvertimeMinutes = this.overtimeMinutes % 60;
            }
        }
    },
    computed: {
        computedOvertimeMinutes: function() {
            return (parseInt(this.editedOvertimeHours) * 60) + parseInt(this.editedOvertimeMinutes);
        },
        saveDisabled: function() {
            return this.overtimeMinutes == this.computedOvertimeMinutes;
        }
    }
}
</script>